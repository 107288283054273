import { ValidationMixin } from '@/shared/mixins';
import { Component } from 'vue-property-decorator';

@Component({})
export default class ModificationEchantillonsValidator extends ValidationMixin {
    protected echantillonRules = [
        (v: string | any) => this.required(v) || 'Selectionnez au moins un échantillon',       
    ];

    public statutOrganismeControle = [
        (v: string | any) => this.required(v) || 'Selectionnez un statut',
    ];

    public statutEchantillon = [
        (v: string | any) => this.required(v) || 'Selectionnez un statut',
    ];

    public typeControle = [
        (v: string | any) => this.required(v) || 'Selectionnez un type de contrôle',
    ];
}
