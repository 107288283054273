import { Vue, Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component/lib/util';
import template from './ModificationEchantillons.Template.vue';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';
import Confirm from '@/components/Confirm.vue';
import { Echantillon } from '@/models/Echantillon.model';
import { Operation } from '@/models';
import { DataTableHeader, ValeurReferentielle, TypeValeurReferentielle } from '@/shared/models';
import { DateHelper } from '@/shared/helpers';
import { ReferentielStoreMethods, getterKeyReferentiel } from '@/store/modules/referentiel/ReferentielStore';
import { Getter, Action } from 'vuex-class';
import CeeDatePicker from '@/components/CeeDatePicker.vue';
import { EchantillonCriteria } from '../EchantillonCriteria.model';
import ModificationEchantillonsValidator from './ModificationEchantillons.Validator';
import CeeAutocomplete from '@/components/CeeAutocomplete.vue';
import ApiAutocompleteHelper from '@/services/ApiAutocompleteHelper';

@Component({
    ...template,
    name: 'ModificationEchantillons',
    components: {
        CeeDatePicker,
        Confirm,
        CeeAutocomplete
    }
})
export default class ModificationEchantillons extends mixins(ModificationEchantillonsValidator, GrilleMixin) {
    public $refs!: Vue['$refs'] & {
        confirm: {
            open: ((title: string | null, message: string | null, options: { color?: string, width?: number, zIndex?: number }) => Promise<boolean>),
        },
        form: HTMLFormElement,
    };

    private loading = false;
    private loadingAction = false;
    private listeEchantillon: Echantillon[] = [];
    private operationsEchantillon: Operation[] = new Array<Operation>();
    private criteresAvances: EchantillonCriteria = new EchantillonCriteria();
    public totalOperationsEchantillon: number = 0;

    // Liste des retour de l'organisme de contrôle.
    @Getter(getterKeyReferentiel(TypeValeurReferentielle.RetourOrganismeControle))
    public statutsOrganismeControle: ValeurReferentielle[];

    // Liste des statuts d'echantillon.
    @Getter(getterKeyReferentiel(TypeValeurReferentielle.StatutEchantillon))
    public statutsEchantillon: ValeurReferentielle[];

    // Liste des types de contrôle.
    @Getter(getterKeyReferentiel(TypeValeurReferentielle.TypeControle))
    public typesControle: ValeurReferentielle[];

    // Permet de récupérer les données référentielles.
    @Action(ReferentielStoreMethods.GET_VALEURS_REFERENTIELLES)
    public getValeursReferentielles: (type: TypeValeurReferentielle) => Promise<ValeurReferentielle[]>;

    public get getVolumeTotal(): number {
        if (this.operationsEchantillon.length > 0) {
            return this.operationsEchantillon.reduce((partialSum, a) => partialSum + (a.volumeCeeClassique + a.volumeCeePrecarite), 0) / 1000;            
        }

        return 0;
    }

    public get canEditTypeControle(): boolean {
        return this.criteresAvances.statutOrganismeControleChoisi >= 3 && this.criteresAvances.statutOrganismeControleChoisi !== 6;
    }

    private headers: DataTableHeader[] = [
        { text: 'Référence interne\n de l\'opération', value: 'numero', align: 'center' },
        { text: 'Date du contrôle', value: 'dateControle', align: 'center' },
        { text: 'Statut \npolitique de \ncontrôle', value: 'statutPolitiqueControle', align: 'center' },
        { text: 'Type de contrôle', value: 'typeControle', align: 'center' },
        { text: 'Statut de\n l\'opération de\n l\'échantillon', value: 'statutEchantillon', align: 'center' },
        { text: 'Raison Sociale\n Bénéficiaire', value: 'raisonSocialeBeneficiaire', align: 'center' },
        { text: 'Code de\n l\'opération', value: 'code', align: 'center' },
        { text: 'Volume CEE\n Classique\n (MWh cumc)', value: 'volumeCeeClassiqueEnMWhCumac', align: 'center' },
        { text: 'Volume CEE\n Précarité\n (MWh cumc)', value: 'volumeCeePrecariteEnMWhCumac', align: 'center' },
        { text: 'Date\n d\'engagement\n des travaux', value: 'dateDebutTravaux', align: 'center' },
        { text: 'Date \nd\'achèvement\n des travaux', value: 'dateFinTravaux', align: 'center' },
        { text: 'Site des travaux', value: 'siteTravaux', align: 'center' },
    ];

    public created() {
        this.getData();
    }

    public getData() {
        this.getAllEchantillon();
        this.getValeursReferentielles(TypeValeurReferentielle.RetourOrganismeControle);
        this.getValeursReferentielles(TypeValeurReferentielle.TypeControle);
        this.statutEchantillon.splice(0, this.statutEchantillon.length);
        const nonDeposable: ValeurReferentielle = new ValeurReferentielle(1, 'NON_DEPOSABLE', 'Non déposable');
        const deposable: ValeurReferentielle = new ValeurReferentielle(2, 'DEPOSABLE', 'Déposable');
        this.statutsEchantillon.push(nonDeposable);
        this.statutsEchantillon.push(deposable);
    }

    public getAllEchantillon(): void {
        this.loading = true;
        this.$http.get(`/echantillon/obtenirTous`)
            .then((result) => {
                const { data: { data: responseData } } = result as ({ data: { data: Echantillon[], isError: boolean, messages: any[] } });
                if (!!responseData && responseData.length > 0) {
                    this.listeEchantillon = responseData;
                }
            }).finally(() => this.loading = false);
    }

    public getOperationsByEchantillonId(echantillonId: number): void {
        this.loading = true;
        this.criteresAvances.operationsSelected = [];
        this.$http.get(`/operation/obtenirOperationsByEchantillonId/${echantillonId}`)
            .then((result) => {
                const { data: { data: responseData } } = result as ({ data: { data: Operation[], isError: boolean, messages: any[] } });
                if (!!responseData) {
                    this.operationsEchantillon = responseData;
                    this.$set(this.pagination, 'totalItems', this.totalOperationsEchantillon);
                }
            }).finally(() => this.loading = false);
    }

    public modifierOperationEchantillon(): void {
        if (this.$refs.form.validate()) {
            this.loadingAction = true;
            this.$refs.confirm.open('Confirmation', 'Confirmez-vous la modification en masse des statuts politique de contrôle des opérations séléctionnées ?', {})
                .then((resultConfirm) => {
                    if (resultConfirm) {
                        this.criteresAvances.operationsIdSelected = this.criteresAvances.operationsSelected.map(x => x.id);
                        this.$http.post(`/echantillon/modifierOperationEchantillon`, this.criteresAvances)
                            .then((result) => {
                                const { data: { data: responseData } } = result as ({ data: { data: Operation[], isError: boolean, messages: any[] } });
                                if (!!responseData) {
                                    this.getOperationsByEchantillonId(this.criteresAvances.echantillonsSelectedId);
                                }
                            }).catch().finally(() => this.loadingAction = false);
                    }
                    else {
                        this.loadingAction = false;
                    }
                });
        }
    }

    public deplacerOperationEchantillon(): void {
        this.loadingAction = true;
        this.$refs.confirm.open('Confirmation', 'Vous allez déplacer les opérations sélectionnées vers l\'échantillon n°2, confirmez-vous ?', {})
            .then((resultConfirm) => {
                if (resultConfirm) {
                    this.criteresAvances.operationsIdSelected = this.criteresAvances.operationsSelected.map(x => x.id);
                    this.$http.post(`/echantillon/deplacerOperationEchantillon`, this.criteresAvances)
                        .then((result) => {
                            const { data: { data: responseData } } = result as ({ data: { data: Operation[], isError: boolean, messages: any[] } });
                            if (!!responseData) {
                                this.getOperationsByEchantillonId(this.criteresAvances.echantillonsSelectedId);
                            }
                        }).catch().finally(() => this.loadingAction = false);
                }
                else {
                    this.loadingAction = false;
                }
            });
    }

    public retirerOperationEchantillon(): void {
        this.loadingAction = true;
        this.$refs.confirm.open('Confirmation', ' Souhaitez-vous retirer les opérations de l\'échantillon ? Cela aura pour effet de retirer ces opérations de l\'échantillon mais elles auront toujours un résultat de retour de l\'organisme de contrôle "non satisfaisant".', {})
            .then((resultConfirm) => {
                if (resultConfirm) {
                    this.criteresAvances.operationsIdSelected = this.criteresAvances.operationsSelected.map(x => x.id);
                    this.$http.post(`/echantillon/retirerOperationEchantillon`, this.criteresAvances)
                        .then((result) => {
                            const { data: { data: responseData } } = result as ({ data: { data: Operation[], isError: boolean, messages: any[] } });
                            if (!!responseData) {
                                this.getOperationsByEchantillonId(this.criteresAvances.echantillonsSelectedId);
                            }
                        }).catch().finally(() => this.loadingAction = false);
                }
                else {
                    this.loadingAction = false;
                }
            });
    }

    // Liste des noms des échantillons. 
    public async nomsEchantillonsPromise(recherche: string): Promise<Echantillon[]> {
        const data = await ApiAutocompleteHelper.getAutocompletePromise<Echantillon>(recherche, `echantillon/obtenirListeEchantillonsForAutocomplete`);
        return data;
    }

    private getDateFormated(date: Date) {
        return DateHelper.format(date);
    }

    private handleToggleSelection(selectAll: boolean) {
        if (selectAll) {
            this.criteresAvances.operationsSelected = this.operationsEchantillon;
        }
        else {
            this.criteresAvances.operationsSelected = [];
        }
    }
}
